import React from "react"

import Layout from "../parts/layout"
import Seo from "../parts/seo"

const Page = () => {
  return (
    <Layout>
      <Seo title="Responsible Business Model Canvas" />

      <section className="no-min-height">
        <div className="row">
          <h1 className="page-title with-subtitle bigger">Responsible Business Model Canvas</h1>
          <h2>for Digital Public Spaces</h2>

          <div className="grid-columns-2">
            <div className="column">
              <p className="page-introduction">This Responsible Business Model Canvas is meant to support you in the creation of a value-based business model for digital public spaces. The canvas and the accompanying methods can help you to give shape to a business model that aligns with the public values that form the foundation of your initiative. You are encouraged to think further than your value-proposition, customers, and revenue streams, and to also consider the foundational values and the business’ potential positive and negative impact on society.</p>

            </div>
            <div className="column no-print">
              <img className="responsive-image with-shadow tilted" src="/responsible-business-model-canvas.png" alt="" />
            </div>
          </div>
        </div>

        <div className="row no-print center flow">
          <h3>Download the canvas</h3>
          <p><a className="button" href="/responsible-business-model-canvas_A4.pdf">Download PDF to print on separate A4s</a></p>
          <p><a className="button" href="/responsible-business-model-canvas_A0.pdf">Download PDF to print on one sheet</a></p>
          <button className="button" onClick={ () => { window.print() } }>Print instructions</button>
        </div>
      </section>



      <section className="no-min-height">
        <div className="row">
          <div className="grid-columns-2">
            <div className="column">
              <h3>workshop timeframe</h3>
              <p>2 – 8 hours (workshop can also be split up into several sessions)</p>
              <h3>number of facilitators</h3>
              <p>1</p>
              <h3>recommended group-size</h3>
              <p>2 – 6 people</p>
            </div>
            <div className="column">
              <h3>workshop materials</h3>
              <ul>
                <li>Print out of the Responsible Business Model Canvas. You need enough space to write or stick post-its in the text boxes, therefore we recommend to print it as large as possible.</li>
                <li><a href="https://ccn.waag.org/navigator/tool/value-cards" target="_blank" rel="noopener noreferrer">Value Cards</a> (for box 1: Foundational Values)</li>
                <li><a href="https://ccn.waag.org/navigator/tool/tarot-cards-of-tech" target="_blank" rel="noopener noreferrer">Tarot Cards of Tech</a> (for box 2 and 3: Positive and Negative impact)</li>
                <li><a href="https://ccn.waag.org/navigator/tool/stakeholders-trust-map" target="_blank" rel="noopener noreferrer">Stakeholder Trust Map</a> (for box 4: Ecosystem)</li>
                <li>Public Stack Reflection Cards</li>
                <li>Sticky notes</li>
                <li>Paper and pens</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="no-min-height">    
        <div className="row flow">
          <h2 className="thin">Workshop instructions</h2>

          <div className="grid-columns-2">
            <div className="column">
              <h3>Take the time</h3>
              <p>Following the numbering in the text boxes, start filling in the canvas. Take your time to discuss the questions in your team, listening to the different perspectives and weighing the various options. Depending on the context of the start-up and the expertise of the team members, some topics might need additional research.</p>
              <p>Depending on the condition of the start-up, this workshop might take anywhere between 2 to 8 hours. Make an estimation of what you already know and which sections might take longer and plan a time frame accordingly. You can split this workshop up into separate sessions.</p>
            </div>
            <div className="column">
              <h3>Evaluate and iterate</h3>
              <p>Business modelling is a dynamic and iterative process. Throughout the process, you are free to edit the answers you gave in earlier boxes. To make editing the canvas easier, it is advisable to use sticky notes. </p>
              <p>Revisit your business model canvas regularly and evaluate whether new experiences and new knowledge demand you to revise certain aspects of your business model. This strengthens the durability and relevancy of your business model. </p>
              <p>At the start of the next iteration, you can use the Public Stack Reflection Cards to reflect on the full stack of your start-up: from the foundational values to the technologies you are using.</p>
            </div>
            <div className="column">
              <h3>Interdisciplinary team</h3>
              <p>Business modelling should always be done with an interdisciplinary team. Include people from different departments of the organisation, as they will bring different expertises and perspectives to the table. </p>
              <p>Invite external stakeholders or experts to participate in (a part of) the business modelling workshop. Especially if the interdisciplinary team is small or not sufficiently diverse. </p>
            </div>
            <div className="column">
              <h3>Co-create</h3>
              <p>Some boxes might be more difficult to fill in than others. Additional co-creative methods can help you to answer these questions as completely as possible. We’ll provide suggestions along the way. </p>
              <p>Challenge yourself to think critically and creatively during the workshop. It can help to invite a trained workshop facilitator who can support and deepen the thinking process of your team. If you are interested in hiring a facilitator from Waag Futurelab, please send an email with your request.</p>
            </div>

            
              <img className="responsive-image no-print" src="/responsible-business-model-canvas_1.jpg" alt="" />
              <img className="responsive-image no-print" src="/responsible-business-model-canvas_2.jpg" alt="" />

          </div>
        </div>
      </section>


      <section className="no-min-height">
        <div className="row flow">
          <h2 className="thin">Box-specific tips</h2>

          <div className="grid-columns-2">
            <div className="column">
              <h3>Box 1:</h3>
              <p>To define the Foundational Values, we recommend using the method <a href="https://ccn.waag.org/navigator/tool/value-cards" target="_blank" rel="noopener noreferrer">Value Cards</a>. This can help to identify the shared values of the group.</p>
            </div>
            <div className="column">
              <h3>Box 2 and 3:</h3>
              <p>The <a href="https://ccn.waag.org/navigator/tool/tarot-cards-of-tech" target="_blank" rel="noopener noreferrer">Tarot Cards of Tech</a> are a useful method to identify the impact of a technological product. They invite you to reflect on the societal consequences of your (digital) services or public spaces.</p>
            </div>
            <div className="column">
              <h3>Box 4:</h3>
              <p>Do a <a href="https://ccn.waag.org/navigator/tool/stakeholders-trust-map" target="_blank" rel="noopener noreferrer">Stakeholder Mapping</a> to get a good overview of the entire ecosystem and to avoid forgetting possible stakeholders. Also consider the people who are not direct users of your service or product, but who are indirectly affected by it. The <a href="https://publicspaces.net/english-section/the-publicspaces-digital-powerwash/" target="_blank" rel="noopener noreferrer">PublicSpaces Digital PowerWash</a> can help to evaluate the (digital) tools and services you are making use of in your start-up and whether they align with the values agreed upon by the PublicSpaces coalition. PublicSpaces also offers a <a href="https://publicspaces.net/activiteiten/hersenspoeling-workshop/" target="_blank" rel="noopener noreferrer">Brainwash workshop</a> in which they help you evaluate the tools and services you use based on your foundational values.</p>
            </div>
            <div className="column">
              <h3>Box 9:</h3>
              <p>Do your research! If you are working with open-source technology, there are various options to generate revenue such as crowdsourcing, value-add services, or freemium models (for a full overview, read this <a href="https://medium.com/made-with-creative-commons/what-is-an-open-business-model-and-how-can-you-generate-revenue-5854d2659b15" target="_blank" rel="noopener noreferrer">blog by Paul Stacey</a>).</p>
            </div>
            <div className="column">
              <h3>Box 11:</h3>
              <p>Research different governmental models (e.g., cooperation, foundation, for-profit, non-profit, etc.) before making your decision. Keep the foundational values you defined in box 1 in mind and ensure your governance model enables you and your stakeholders to adhere to these values.</p>
            </div>
            <div className="column">
              <h3>Parking lot:</h3>
              <p>If at any point in the process you encounter a question that you are not ready to answer or an issue that you must investigate further, write it down on a sticky note and place it in this parking lot. Perhaps you can come back to this question later in the process or during a next iteration. </p>
            </div>
          </div>
        </div>
      </section>


      <section className="no-min-height" style={{ marginBlockStart: "10rem" }}>
        <div className="row flow center">
          <em>Ontwikkeld in samenwerking met de <a href="https://www.hva.nl/" target="_blank" rel="noopener noreferrer">Hogeschool&nbsp;van&nbsp;Amsterdam</a></em>
          <a className="logo-link" href="https://www.hva.nl/" target="_blank" rel="noopener noreferrer"><img src="/logo_hva.svg" alt="Logo Hogeschool van Amsterdam" /></a>
          <em>Gefinancierd door <a href="https://www.clicknl.nl/" target="_blank" rel="noopener noreferrer">ClickNL</a></em>
          <a className="logo-link" href="https://www.clicknl.nl/" target="_blank" rel="noopener noreferrer"><img src="/logo_clicknl.svg" alt="Logo ClickNL" /></a>
        </div>
      </section>

    </Layout>
  )
}

export default Page
